<template>
  <div>
    <div v-if="fetching" class="ant-modal-root voucher-detail_loader">
      <div class="ant-modal-mask">
        <div
          class="ant-modal-wrap d-flex justify-content-center align-items-center"
        >
          <a-spin />
        </div>
      </div>
    </div>
    <a-modal
      v-if="used"
      :visible="!!used"
      class="voucher-detail_modal"
      :footer="false"
      @change="back"
    >
      <template #title>
        <div class="font-weight-bold">
          {{ voucher.voucher_code }}
        </div>
        <div class="small mb-n2">
          <span class="text-muted">
            {{ parseDate(voucher.validity.from) }} -
            {{ parseDate(voucher.validity.until) }}
          </span>
        </div>
      </template>
      <div class="row">
        <div class="col-sm-6 mb-3">
          <dl class="">
            <dt class="col-sm-6">
              Voucher Code
            </dt>
            <dd class="col-sm-8">
              {{ voucher.voucher_code }}
            </dd>

            <dt class="col-sm-6">
              Voucher Type
            </dt>
            <dd class="col-sm-8">
              {{ parseTypeVoucher(voucher.type_id) }}
            </dd>

            <!-- <dt class="col-sm-6"> Voucher Periode </dt>
            <dd class="col-sm-8">{{ parseDate(voucher.validity.from) }} - {{ parseDate(voucher.validity.until) }}</dd> -->
            <dt class="col-sm-6">
              Vocucher Value
            </dt>
            <dd class="col-sm-8">
              {{ voucher.transaction_data.value | currency }}
            </dd>
          </dl>
        </div>
        <div class="col-sm-6 mb-3">
          <dl class="">
            <!-- <dt class="col-sm-6"> Vocucher Value </dt>
            <dd class="col-sm-8">{{ voucher.transaction_data.value | currency}}</dd> -->

            <dt class="col-sm-8">
              Minimun Transaction
            </dt>
            <dd class="col-sm-8">
              {{ voucher.transaction_data.min_transaction | currency }}
            </dd>

            <dt class="col-sm-6">
              Max Discount
            </dt>
            <dd class="col-sm-8">
              {{ voucher.transaction_data.max_discount | currency }}
            </dd>
          </dl>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 mt-3">
          <h5>List of orders using vouchers</h5>
          <a-table
            :columns="columns"
            :data-source="used"
            :pagination="pagination"
            :row-key="(data, index) => index"
            class="table-voucherUsed"
            @change="handleTableChange"
          >
            <span slot="order_date" slot-scope="text, record">
              <div>
                {{ parseDate2(record.order_date) }}
              </div>
            </span>
            <span slot="order_state" slot-scope="text, record">
              {{ changeDisplayNameStatus(record.order_state) }}
            </span>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Order Number',
    dataIndex: 'order_no',
    key: 'order_no',
    class: 'text-center',
  },
  {
    title: 'Status',
    dataIndex: 'order_state',
    scopedSlots: { customRender: 'order_state' },
    key: 'order_state',
    class: 'text-center',
  },
  {
    title: 'Date',
    dataIndex: 'order_date',
    key: 'order_date',
    scopedSlots: { customRender: 'order_date' },
    class: 'text-center',
  },
]

export default {
  name: 'voucher.detail',
  data: function () {
    return {
      fetching: true,
      used: null,
      voucher: null,
      columns,
      page: 1,
      limit: 7,
      pagination: {},
    }
  },
  watch: {
    voucher() {
      this.$nextTick(() => {
        const modal = document.querySelector('.voucher-detail_modal')
        if (modal) {
          const header = modal.querySelector('.ant-modal-header')
          const body = modal.querySelector('.ant-modal-body')
          const height = header.offsetHeight || 0

          body.style.maxHeight = `calc(100vh - ${height}px`
        }
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0)
      document.querySelector('body').style.overflow = 'hidden'
    })

    // fetch an voucher
    this.getVoucher()
  },
  beforeDestroy() {
    document.querySelector('body').style.overflow = 'auto'
  },
  methods: {
    back() {
      this.$router.push({
        name: 'promotion-channel.web-voucher',
        query: this.$route.query,
      })

      this.$nextTick(() => {
        document.querySelector('body').style.overflow = 'auto'
        window.scrollTo(0, 0)
      })
    },
    getVoucher() {
      this.$store
        .dispatch('web_platform_channel/discounts/GETVOUCHERDETAIL', {
          ...this.$route.query,
          ...this.$route.params,
        })
        .then(({ data }) => {
          this.voucher = data
          // this.used = data
          return this.$store.dispatch(
            'web_platform_channel/discounts/GETUSEDVOUCHER',
            {
              ...this.$route.query,
              ...this.$route.params,
              voucher_code: data.voucher_code,
              page: this.page,
              limit: this.limit,
            },
          )
        })
        .then(response => {
          // this.pagination.total = response.total
          // this.pagination.limit = this.limit
          // this.pagination.current = this.page
          const pagination = { ...this.pagination }
          pagination.total = response.total
          this.pagination = pagination
          this.totalUsed = response.total
          this.used = response.data
        })
        .catch(err => {
          if(err.response.data.status_code === 404) {
            this.$message.error("Voucher belum pernah digunakan!")
          } else {
            this.$message.error(err?.response?.message?.data || err?.message)
          }
          return this.back()
        })
        .finally(() => {
          this.fetching = false
        })
    },
    parseTypeVoucher(type) {
      switch (type) {
        case 1:
          return 'Transaction Voucher'

        case 2:
          return 'Product Item Voucher'

        case 3:
          return 'Shipping Voucher'

        case 4:
          return 'Voucher Brand'

        default:
          break
      }
    },
    parseDate(date) {
      return this.$moment(date * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    parseDate2(date) {
      return this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    handleTableChange(pagination) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.$store
        .dispatch('web_platform_channel/discounts/GETUSEDVOUCHER', {
          ...this.$route.query,
          voucher_code: this.voucher.voucher_code,
          page: pagination.current,
          limit: this.limit,
        })
        .then(response => {
          this.used = response.data
        })
        .catch(err => {
          this.$message.error(err?.response?.message?.data || err?.message)
        })
    },
    changeDisplayNameStatus(param) {
      // new, on_process, sent, deliverd, completed => sendiri
      // draft dan waiting_payment => jadi satu
      switch (param) {
        case 'draft':
        case 'waiting_payment':
          return 'Waiting Payment'
        case 'new':
          return 'New'
        case 'on_process':
        case 'confirmed':
        case 'ready_to_pickup':
          return 'On Process'
        case 'sent':
          return 'Sent'
        case 'delivered':
          return 'Delivered'
        case 'completed':
          return 'Completed'
        case 'canceled':
        case 'retoured':
        case 'payment_expired':
        case 'payment_unfinish':
        case 'payment_declined':
          return 'Canceled'
        case 'on_cancel_req':
        case 'on_retour_req':
          return 'Cancel Request'
        default:
          return param
      }
    },
  },
}
</script>

<style lang="scss">
.voucher-detail_loader {
  .ant-spin-dot-item {
    background-color: #fff;
  }
}

.voucher-detail_modal {
  .ant-modal {
    margin: 0 0 0 auto !important;
    top: 0;
    padding-bottom: 0;

    &-content {
      height: 100vh;
      border-radius: 0;
    }

    &-body {
      overflow: auto;
    }
  }

  h5 {
    font-size: 0.9rem;
    color: #999;
    text-transform: uppercase;
  }
  dl {
    align-items: flex-start;

    dt,
    dd {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      margin-bottom: 0;
    }

    dt {
      text-transform: uppercase;
      font-size: 90%;
      line-height: 1.25;
    }

    &:not(.row) {
      dt,
      dd {
        padding: 0;
      }

      dd {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.voucher-detail__items {
  margin-top: 3rem;
  display: table;

  &__header {
    display: table-header-group;

    > * {
      display: table-cell;
      text-transform: uppercase;
      font-weight: 300;
      padding: 0.5rem;
      font-size: 85%;
      background: #f0f2f4;
    }

    &__amount {
      padding-right: 3.5rem !important;
    }
  }

  &__item {
    display: table-row;
    margin-left: -1rem;
    margin-right: -1rem;
    width: 100%;

    > div {
      display: table-cell;
      vertical-align: top;
      padding: 0.5rem 0.5rem;
      border-bottom: 1px solid #efefef;
      width: 100%;
    }
  }

  .media {
    img {
      max-width: 75px;
      width: 100%;
    }
  }
}

.table-voucherUsed {
  tr {
    > th {
      padding: 10px !important;
      font-weight: bold !important;
    }
  }
}
</style>
